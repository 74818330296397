.contact-cards {
    width: 100%;
    padding: 2rem 0 ;
    margin: auto;
}

.contact-card {
    width: 250px;
    box-shadow: -2px -1px 36px -10px rgba(0,0,0,0.10);
    -webkit-box-shadow: -2px -1px 36px -10px rgba(0,0,0,0.10);
    -moz-box-shadow: -2px -1px 36px -10px rgba(0,0,0,0.10);
}

.contact-card h1 {
    padding: 1rem 0 0 0;
}

.contact-cards-contents {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.contact-card-icon {
    width: 150px;
    margin: auto;
    padding: 1rem;
}

.contact-card-info {
    text-align: center;
}

.contact-card-icon img {
    width: 100%;
}

@media screen and (max-width:700px) {

    .contact-card h1 {
        font-size: 1rem;
    }
}