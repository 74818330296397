.mission1 {
    background-color: white;
    padding: 3rem 0;
}

.mission1-header {
    margin: auto;
    padding: 1rem;
    width: 80%;
} 

.mission1-header h1 {
    font-size: 2rem;
    padding: .5rem 0;
    text-align: center;
    font-family: 'Lilita One', cursive;

}

.mission1-header h2 {
    text-align: center;
    padding: .5rem 0;
    color: var(--primary-text);
    font-size: 2rem;
}

.mission1-header p {
    text-align: center;
    font-size: 18px;
}

.mission1-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding: 2rem 0;
}


.mission1-flexItem {
    display: flex;
    gap: 1rem;
    justify-content: center;
    width: 40%;
}


.mission1-left {
    width: 300px;
}

.mission1-left img {
    width: 100%;
}


.mission1-right {
    width: 50%;
    margin: auto;
}

.mission1-right h1 {
    padding: .8rem 0;
    font-family: 'Indie Flower', cursive;
    font-size: 2rem;
    color: rgb(230, 142, 10);
}

.mission1-right p {
    font-size: 16px;
}


.mission1-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
}


.mission1-flexItem img {
    width: 100%;
}


@media  screen and (max-width:940px) {
    .mission1-header {
        width: 100%;
    } 

    .mission1-right h1 {
        text-align: center;
        padding: 0;
    }

    .mission1-flexItem {
        width: 90%;
    }
    
}


@media  screen and (max-width:700px) {

    .mission1-flexItem {
        flex-wrap: wrap;
    }

    .mission1-left {
        width: 100%;
        padding: 0 1rem;
    }

    
    .mission1-flexItem {
        width: 90%;
        padding: 1rem;
    }
    
}