@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(15, 15, 15, 0.281);
  }
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/team-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


  .about .about-content {
    display: block;
    position: absolute;
    top: 60%;
    padding: 1rem;
    width: 50%;
}

.about-accent {
    width: 100%;
    margin: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: -1;
}

.about-accent img {
    width: 100%;
    opacity: 0.6;
}

.about h2 {
    color: rgb(240, 232, 232);
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
}

.about h1 {
    font-size: 5rem;
    font-family: 'Indie Flower', cursive;
    color: rgb(255, 255, 255);
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.about h2 {
    color: white;
    font-size: 1.5rem;
}

.about h1 {
    font-size: 3rem;
}


.about .about-content {
    width: 70%;
}
}


@media screen and (max-width:700px) {
    .about .about-content {
        width: 100%;
    }
}