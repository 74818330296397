.about1 {
    width: 100%;
    position: relative;
    background: rgb(255, 255, 255);
    padding: 2rem 0;
}

.about1-header {
    width: 100%;
}


.about1  p {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}


.about1-header h1 {
    font-size: 2rem;
    padding: 1rem;
    text-align: center;
}


@media screen and (max-width: 940px) {
    .team-section .content {
        width: 100%;
    }

    .team-section h1 {
        font-size: 25px;
    }

    .team-section p {
        font-size: 15px;
    }
}







