@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.employment {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(22, 22, 22, 0.089);
  }
  
  .employment:before {
    content: '';
    position: absolute;
    background: url('../../assets/employment-landing.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.employment .employment-content {
    display: block;
    position: absolute;
    bottom: 30%;
    left: 0%;
    padding: 1rem;
    width: 50%;
}

.employment-accent {
    width: 100%;
    margin: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: -1;
}

.employment-accent img {
    width: 100%;
    opacity: 0.6;

}

.employment h1 {
    font-size: 5rem;
    font-family: 'Indie Flower', cursive;
    color: rgb(255, 255, 255);
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.employment h2 {
    color: white;
    font-size: 1.5rem;
}

.employment h1 {
    font-size: 3rem;
}


.employment .employment-content {
    bottom: 20%;
    left: 0%;
    width: 100%;
}

}

